.App {
  width: 100%;
}
*{
  margin: 0;
  padding: 0;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.header{
  background-color: rgb(151, 3, 3);
  width: 100%;
  display: flex;
  justify-content:space-around;
}
body{
  background-color: rgb(220, 254, 255);

}
.logo{
  width: 200px;
  height: 200px;
  margin:20px;
}
.logo img{
  width: 100%;
  height: 100%;
  border-radius: 30px;
  box-shadow: 2px 2px 2px rgb(216, 85, 85);
}
.texto{
  margin: 20px;
  color: aliceblue;
  text-align: center;
  border: 2px solid;
  border-color: aliceblue;
  border-radius: 10px;
  background-color: rgb(164, 50, 50);
}
.texto h1{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 60px;
}
.texto h2{
  color: blue;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 50px;
}
.intro{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-top: 30px;
  background-color: orangered;
  padding: 30px;
  border: 2px solid;
  border-color: aliceblue;
  border-radius: 30px;
  box-shadow: 5px 5px 5px aliceblue;
}
.intro h2{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 30px;
  color: aliceblue;
}
.introduction{
  display: flex;
  width: 100%;
}

.text-introduction{
  width: 60%;
  margin: 30px;
  background-color: rgb(0, 60, 255);
  border: 2px solid;
  border-color: aliceblue;
  border-radius: 20px;
  padding: 20px;
  height: max-content;
  box-shadow: 2px 2px 2px gray;
}
.text-introduction span{
  font-size: 20px;
  color: rgb(249, 248, 248);
}
.text-introduction h1{
  color: red;
}
.ambulancia{
  width: 75%;
  height: auto;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.ambulancia img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.ambulancia img:hover{
  transform: rotate(5deg);
  transition: 0.5s;
}
.saludIntroduction img{
  width: 100%;
  height: 100%;
}
.saludIntroduction{
  width: 60%;
  padding: 20px;
}
.contactWhatsapp{
  background-color: green;
  text-align: center;
  width: 150px;
  border-radius: 20px;
  height: max-content;
}
.contactWhatsapp a{
  color: aliceblue;
  text-decoration: none;
}


.cards{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  padding: 20px;
}
.cards h3{
  color: blue;
}
.card-one{
  width: 70%;
  height: 100%;
  background-color: rgb(132, 221, 224);
  padding: 20px;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 2px 2px 2px gray;
}
.card-two{
  width: 70%;
  height: 100%;
  background-color: rgb(58, 227, 233);
  padding:20px;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 2px 2px 2px gray;
}
.imageCard img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 2px 2px 2px gray;
}
.imageCard{
  width: 90%;
  height: 250px;
  margin: auto;
  margin-bottom: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.slider{
  width: 100%;
  color: red;
  text-align: center;
  padding: 20px;
}
.slider h1{
  font-size: 80px;
}
#carouselExampleControls{
  border-style: solid;
  border-color: red;
  
}

.eventos{
  color: red;
  text-align: center;
}
.textEventos{
  margin: 30px;
  background-color: aliceblue;
  box-shadow: 1px 1px 1px gray;
  padding: 20px;
  border-radius: 15px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.textEventos h1{
  padding: 10px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 50px;
}
.textEventos:hover{
  background-color: orangered;
  color: antiquewhite;
}
.imagenes img{
  width: 100%;
  height: 100%;
  border-radius: 30px;
  box-shadow: 2px 2px 2px gray;
}
.imagenes{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  padding: 15px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.imagenes img:hover{
  transform: scale(1.2);
  transition: 0.8s;
}

.videoPresentacionAmbulancia{
  width: 60%;
  height: auto;
  margin: auto;
}
.videoPresentacionAmbulancia video{
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: red;
  border-radius: 10px;
  box-shadow: 1px 1px 1px blue;
}


.contact{
  width: 100%;
  background-color: aquamarine;
  height: max-content;
  padding: 30px;
  display: flex;
}
.contactWhats{
  background-color: green;
  text-align: center;
  width: 100%;
  border-radius: 20px;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 50px;
}
.contactWhats:hover{
  box-shadow: 3px 3px 3px gray;
  border: 2px solid;
  border-color: wheat;
  background-color:orangered;
}
.contactWhats a:hover{
  color: green;
}
.contactWhats a{
  text-decoration: none;
  color: aliceblue;
}
.videos{
  width: 100%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: brown;
  padding: 40px;
  overflow: scroll;
  gap: 20px;
}
.videos video{
  border-radius: 30px;
  box-shadow: 2px 2px 2px gray;
  border: 1px solid;
  border-color: aliceblue;
  margin: auto;
}
.videosEventos{
  background-color: brown;
  padding-top: 20px;
}
.correo{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: red;
  text-align: center;
  border-radius: 20px;
  padding: 30px;
}
.correo a {
  color: antiquewhite;
  text-decoration: none;
  font-size: 50px;
}
.correo:hover{
  background-color: antiquewhite;
  color: red;
}
.correo a:hover{
  color: red;
}
.contactos{
  margin: 30px;
  margin-left: auto;
  margin-right: auto;
}
.infoContact{
  width: 45%;
  background-color: rgba(249, 249, 249, 0.355);
  box-shadow: 1px 1px 1px gray;
  border-radius: 10px;
  color: blue;
  padding: 30px;
}
.infoContact h4{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.videoAmbulancia{
  width: 100%;
  display: flex;
  margin: 50px;
}
.videoAmbulancia video{
  border: 1px solid;
  border-color: red;
}
.videoAmbulancia video:hover{
  transform:scale(1.1);
  transition: 3s;
}
.infoVideo{
  width: 50%;
  height: auto;
  background-color: aliceblue;
  margin: 30px;
  padding: 20px;
  box-shadow: 2px 2px 2px gray;
  color: red;
  border: 2px solid;
  border-color: gray;
}
.infoVideo:hover{
  transform: translate(20px);
  transition: 1s;
}
.infoVideo p{
  font-size: 25px;
}
.infoVideo h2{
  font-size: 45px;
}
@media (max-width: 600px){
  .header{
    display: block;
    height: max-content;
    padding: 20px;
  }
  .texto h1{
    text-align: center;
    font-size: 40px;
  }
  .logo img{
    width: 100%;
    height: 100%;
  }
  .logo{
    width: 250px;
  }
  .intro{
    width: 95%;
    margin-bottom: 20px;
  }
  .cards{
  grid-template-columns: repeat(1, 1fr);
  }
  .card-one{
    width: 95%;
  }
  .card-two{
    width: 95%;
  }
  .slider h1{
    font-size: 60px;
  }
  .imagenes{
  grid-template-columns: repeat(1, 1fr);
  }
  .correo{
    width: 95%;
  }
  .correo a{
    font-size: 17px;
    text-decoration: none;
  }
  .ambulancia{
    width: 100%;
  }
  .salud{
    width: 100%;
    margin-left: -10px;
  }
  .introduction{
    display:flexbox;
    flex-direction: column-reverse;
  }
  
  .saludIntroduction{
    width: 100%;
  }
  .text-introduction{
    width: 95%;
    margin: auto;
  }
  .contactWhats{
    width: 95%;
  }
  .contactWhats a{
    font-size: 35px;
  }
  .contact{
    display: block;
    width: 100%;
  }
  .infoContact{
    width: 100%;
    overflow:scroll;
  }
  .videoAmbulancia{
    display:flexbox;
    flex-direction: column-reverse;
    margin: auto;
    padding: 30px;
  }
  .infoVideo{
    width: 95%;
    margin: auto;
    margin-bottom: 30px;
  }
  .videoAmbulancia{
    margin: 10px;
    margin-top: 50px;
    width: 95%;
  }
  .videoPresentacionAmbulancia{
    width: 95%;
  }
  .videos{
    overflow: scroll;
    gap: 20px;
  }
}